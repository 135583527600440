var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("模板预览")])
          ]),
          _vm._v(" "),
          _c("temp-search", {
            ref: "ref_1000",
            attrs: {
              "search-data": _vm.searchModel_1000.searchData,
              "operate-data": _vm.searchModel_1000.operateData,
              "remote-func": this.remoteFunc,
              "on-click-operate": _vm.onClickSearchOperate,
              "on-condition-change": _vm.onConditionChange
            }
          })
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "1px" }, attrs: { shadow: "never" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("列表")])
          ]),
          _c("temp-table", {
            ref: "ref_1001",
            attrs: {
              "table-model": _vm.tableModel_1001.tableModel,
              "row-button": _vm.tableModel_1001.rowButton,
              "show-summary": _vm.tableModel_1001.showSummary,
              "show-pagination": _vm.tableModel_1001.showPagination,
              "show-selection": _vm.tableModel_1001.showSelection,
              stripe: _vm.tableModel_1001.stripe,
              border: _vm.tableModel_1001.border,
              "req-table-data": _vm.reqTableData,
              "req-add": _vm.reqAdd,
              "req-del": _vm.reqDel,
              "on-click-link": _vm.onClickLink,
              "on-click-row-button": _vm.onClickRowButton,
              "on-summary": _vm.onSummary,
              "on-switch-change": _vm.onSwitchChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }