import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
import setting from '@/setting';
import { systemInfo } from '@/api/apis';
export default {
  namespaced: true,
  state: {
    systemId: ''
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} context
     * @param {*} info info
     */
    set: function set(_ref, sys) {
      var state = _ref.state,
          dispatch = _ref.dispatch;
      return new Promise(function _callee2(resolve) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                systemInfo({}).then(function _callee(res) {
                  return regeneratorRuntime.async(function _callee$(_context) {
                    while (1) {
                      switch (_context.prev = _context.next) {
                        case 0:
                          console.log('systemInfo', res); // setting.projectName = res.sys_name+'后台管理系统'

                          dispatch('d2admin/db/set', {
                            dbName: 'sys',
                            path: 'systemInfo',
                            value: res,
                            user: true
                          }, {
                            root: true
                          });
                          resolve();

                        case 3:
                        case "end":
                          return _context.stop();
                      }
                    }
                  });
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },

    /**
     * @description 从数据库取用户数据
     * @param {Object} context
     */
    load: function load(_ref2) {
      var state = _ref2.state,
          dispatch = _ref2.dispatch;
      return new Promise(function _callee3(resolve) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve(); // systemInfo({}).then(async res=>{
                //   console.log('systemInfo',res)
                //   // setting.projectName = res.sys_name+'后台管理系统'
                //   setting.appApi.previewUrl = 'http://'+res.sys_url
                //   setting.proId = res.db_name
                //   resolve()
                // })

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    }
  }
};