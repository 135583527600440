import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "core-js/modules/es.string.sub";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import roleAdd from './roleAdd';
import { mapState, mapGetters, mapActions } from 'vuex';
import { menuList, roleDel, roleList } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    roleAdd: roleAdd
  },
  data: function data() {
    var _this = this;

    return {
      asideMenuList: [],
      tableModel_1000: {
        "type": "tableTemplate",
        "id": "id_1000",
        "del": false,
        "tableModel": [{
          "label": "ID",
          "show": 1,
          "add": 0,
          "detail": 0,
          "excel": 0,
          "width": "60",
          "type": "text",
          "rowAddField": "",
          "required": 0,
          "prop": "id"
        }, {
          "label": "角色名称",
          "show": 1,
          "add": 0,
          "detail": 0,
          "excel": 0,
          "width": "120",
          "type": "text",
          "rowAddField": "",
          "required": 0,
          "prop": "role_name"
        }, {
          "label": "权限",
          "show": 1,
          "add": 0,
          "detail": 0,
          "excel": 0,
          "width": null,
          "type": "text",
          "rowAddField": "",
          "required": 0,
          "prop": "rules_desc"
        }, {
          "label": "是否超管",
          "show": 1,
          "add": 0,
          "detail": 0,
          "excel": 0,
          "width": "80",
          "type": "switch",
          "rowAddField": "",
          "required": 0,
          "prop": "level"
        }, {
          "label": "是否启用",
          "show": 1,
          "add": 0,
          "detail": 0,
          "excel": 0,
          "width": "80",
          "type": "switch",
          "rowAddField": "",
          "required": 0,
          "prop": "status"
        }],
        "tableSwitchProps": {
          "status": {
            "activeValue": 1,
            "inactiveValue": 0,
            "integer": 1,
            "id": 0,
            "relation": false,
            "relationId": ""
          },
          "level": {
            "activeValue": 1,
            "inactiveValue": 0,
            "integer": 1,
            "id": 1,
            "relation": false,
            "relationId": ""
          }
        },
        "tableSelectProps": {},
        "rowButton": [{
          "title": "编辑",
          "type": "primary",
          "tag": "default",
          "id": ""
        }, {
          "title": "删除",
          "type": "danger",
          "tag": "del",
          "id": ""
        }],
        "stripe": false,
        "border": true,
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "title": "角色",
        "tableName": "t_system_role",
        "isTree": false,
        "headerButton": [{
          "title": "添加",
          "type": "primary",
          "tag": "default",
          "id": ""
        }]
      },
      remoteFunc: {}
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this2 = this;

    this.table = this.$refs.ref_1000;
    menuList({}).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this2.asideMenuList = res;

              _this2.table.reloadData();

            case 2:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    menuNameById: function menuNameById(id) {
      var name = '';

      var findItem = function findItem(ary, tid) {
        for (var i in ary) {
          var item = ary[i];

          if (item.id === tid) {
            name = item.menu_name;
            return;
          }

          findItem(item.sub, tid);
        }
      };

      findItem(this.asideMenuList, id);
      return this.$t(name);
    },
    reqTableData: function reqTableData(params, resolve) {
      var _this3 = this;

      roleList(params).then(function _callee2(res) {
        var i, item, tmp, j;
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                for (i in res) {
                  item = res[i];
                  tmp = '';

                  for (j in item.rules) {
                    tmp += '[' + _this3.menuNameById(item.rules[j]) + '] ';
                  }

                  item.rules_desc = tmp;
                }

                resolve({
                  total: 0,
                  data: res
                });

              case 2:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      resolve();
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      roleDel({
        id: ids[0]
      }).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      //点击列表按钮回调
      if (btnIndex === 0) {
        this.$refs['roleAdd'].show(row);
      } else {}
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      //点击列表头部按钮回调
      this.$refs['roleAdd'].show();
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = data.header;
        var results = data.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};