import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/suifeng/Desktop/projects/2022/sdML/sdMLAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";
import "regenerator-runtime/runtime";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import memberMulGrade from './memberMulGrade';
import memberMove from './memberMove';
import memberMoney from './memberMoney';
import memberPwd from './memberPwd';
import memberMulLevel from './memberMulLevel';
import memberMulStore from './memberMulStore';
import memberRegion from './memberRegion';
import memberMulImportRegion from './memberMulImportRegion';
import { mapState, mapGetters, mapActions } from 'vuex';
import { clientLogin, configGradeList, configLevelList, configStoreList, expectNumList, getMemberPwd, memberBatchRecharge, memberMulUpdateState, userAdd, userDel, userList } from '../../../../api/apis';
export default {
  name: 'memberManage',
  components: {
    memberMulLevel: memberMulLevel,
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    memberMulGrade: memberMulGrade,
    memberMove: memberMove,
    memberMoney: memberMoney,
    memberPwd: memberPwd,
    memberMulStore: memberMulStore,
    memberMulImportRegion: memberMulImportRegion,
    memberRegion: memberRegion
  },
  data: function data() {
    var _this = this;

    return {
      activeNames: 'search',
      gradeList: [],
      levelList: [],
      storeList: [],
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "number",
              "value": ""
            }, {
              "desc": "会员姓名",
              "key": "real_name",
              "value": ""
            }, {
              "desc": "手机号",
              "key": "phone",
              "value": ""
            }, {
              "desc": "推荐上级",
              "key": "direct",
              "value": ""
            }, {
              "desc": "所属服务中心",
              "key": "store_number",
              "value": ""
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "顶点编号",
              "key": "top_number"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "注册日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_3"]
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "2"
            }],
            "title": "顶点限制登录",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "3"
            }],
            "title": "子点限制登录",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "5"
            }],
            "title": "冻结",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "6"
            }],
            "title": "注销",
            "total": 0,
            "type": "button"
          }],
          "id": 4,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "会员状态",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "is_recharge",
              "value": "1"
            }],
            "title": "是",
            "type": "button"
          }, {
            "field": [{
              "key": "is_recharge",
              "value": "0"
            }],
            "title": "否",
            "type": "button"
          }],
          "id": 13,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "服务中心",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "multiple": false,
            "type": "select"
          }],
          "id": 5,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "会员等级",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_5"]
        }, {
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 14,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "会员资格",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_14"]
        }, {
          "title": "按条件",
          "select": 0,
          "type": 2,
          "id": 15,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "input-mul-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "money1",
              "value": "",
              "desc": "消费账户"
            }],
            "operator": [{
              "key": "gte",
              "value": ">="
            }, {
              "key": "lte",
              "value": "<="
            }, {
              "key": "gt",
              "value": ">"
            }, {
              "key": "lt",
              "value": "<"
            }, {
              "key": "eq",
              "value": "="
            }]
          }, {
            "type": "input-mul-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "money2",
              "desc": "奖金账户"
            }],
            "operator": [{
              "key": "gte",
              "value": ">="
            }, {
              "key": "lte",
              "value": "<="
            }, {
              "key": "gt",
              "value": ">"
            }, {
              "key": "lt",
              "value": "<"
            }, {
              "key": "eq",
              "value": "="
            }]
          }],
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "96",
          "tag": "default",
          "title": "批量定级",
          "type": "primary"
        }, {
          "id": "97",
          "tag": "default",
          "title": "会员注销",
          "type": "info"
        }, {
          "id": "98",
          "tag": "default",
          "title": "补扣款",
          "type": "primary"
        }, {
          "id": "99",
          "tag": "default",
          "title": "网体限制登录",
          "type": "danger"
        }, {
          "id": "100",
          "tag": "default",
          "title": "单点限制登录",
          "type": "danger"
        }, {
          "id": "101",
          "tag": "default",
          "title": "冻结",
          "type": "danger"
        }, {
          "id": "102",
          "tag": "default",
          "title": "恢复状态",
          "type": "success"
        }, {
          "id": "103",
          "tag": "default",
          "title": "一键登录",
          "type": "success"
        }, {
          "id": "104",
          "tag": "default",
          "title": "密码修改",
          "type": null
        }, {
          "id": "105",
          "tag": "default",
          "title": "批量服务中心",
          "type": null
        }, {
          "id": "106",
          "tag": "default",
          "title": "撤销服务中心",
          "type": null
        }, {
          "id": "107",
          "tag": "default",
          "title": "删除会员",
          "type": "danger"
        }, {
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "批量资格",
          "type": null
        }, {
          "icon": "",
          "id": "1240",
          "tag": "default",
          "title": "批量店补",
          "type": null
        }, {
          "icon": "",
          "id": "127",
          "tag": "default",
          "title": "网体注销",
          "type": null
        }, {
          "icon": "",
          "id": "128",
          "tag": "default",
          "title": "网体冻结",
          "type": null
        }, {
          "icon": "",
          "id": "129",
          "tag": "default",
          "title": "网体恢复状态",
          "type": null
        }],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "108",
          "tag": "edit",
          "title": "信息修改",
          "type": "primary"
        }, {
          "tag": "default",
          "type": null,
          "icon": "",
          "title": "获取密码",
          "id": "300"
        }],
        "showPagination": true,
        "showSelection": true,
        "stripe": true,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "number"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "真实姓名",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "real_name"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "推荐人",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "direct"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "当前等级",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "grade"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "资格",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "level"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "注册期数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "expect_num"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员状态",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "status"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "移动电话",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "phone"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "身份证号",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "card_id"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "消费账户",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "money1"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "奖金账户",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "money2"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "复销账户",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "money4"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否服务中心",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "is_recharge"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "mark"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "上次登录日期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "last_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "创建日期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "add_time"
        }],
        "tableName": "t_user",
        "tableRadioProps": {},
        "tableSelectProps": {
          "status": {
            "data": [{
              "key": 0,
              "value": "未激活"
            }, {
              "key": 1,
              "value": "已激活"
            }, {
              "key": 2,
              "value": "单点限制登录"
            }, {
              "key": 3,
              "value": "网体限制登录"
            }, {
              "key": 4,
              "value": "退货"
            }, {
              "key": 5,
              "value": "冻结"
            }, {
              "key": 6,
              "value": "注销"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "grade": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_grade"
          },
          "is_recharge": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 10,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "level": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 12,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_level"
          },
          "store": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 13,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_store"
          }
        },
        "tableSwitchProps": {},
        "title": "会员",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], // [{key:"add_time",value:_this.$g.fun.getDateRange(new Date(), 0, true)}],
          // [{key:"add_time",value:_this.$g.fun.getDateRange(new Date(), 1, true)}],
          // [{key:"add_time",value:_this.$g.fun.getDateRange(new Date(), 7, true)}],
          // [{key:"add_time",value:_this.$g.fun.getDateRange(new Date(), 30, true)}],
          // [{key:"add_time",value:_this.$g.fun.getDateRange(new Date(), 365, true)}],
          [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_5: function modelConditionFunc_5(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({
            page: 1,
            limit: 50
          }).then(function _callee(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];
                    data.push({
                      key: 'grade',
                      value: '',
                      desc: '全部'
                    });

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'grade',
                        value: item.tag,
                        desc: item.name
                      });
                    }

                    resolve(tag, [data]);

                  case 4:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_14: function modelConditionFunc_14(tag, relationData, resolve) {
          //筛选条件数据
          configLevelList({
            page: 1,
            limit: 50
          }).then(function _callee2(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];
                    data.push({
                      key: 'level',
                      value: '',
                      desc: '全部'
                    });

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'level',
                        value: item.tag,
                        desc: item.name
                      });
                    }

                    resolve(tag, [data]);

                  case 4:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        },
        tableFunc_select_grade: function tableFunc_select_grade(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({
            page: 1,
            limit: 50
          }).then(function _callee3(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    _this.gradeList = data;
                    resolve(tag, data);

                  case 4:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        },
        tableFunc_select_level: function tableFunc_select_level(tag, relationData, resolve) {
          //筛选项模型数组
          configLevelList({}).then(function _callee4(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee4$(_context4) {
              while (1) {
                switch (_context4.prev = _context4.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    _this.levelList = data;
                    resolve(tag, data);

                  case 4:
                  case "end":
                    return _context4.stop();
                }
              }
            });
          });
        },
        tableFunc_select_store: function tableFunc_select_store(tag, relationData, resolve) {
          //筛选项模型数组
          configStoreList({}).then(function _callee5(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee5$(_context5) {
              while (1) {
                switch (_context5.prev = _context5.next) {
                  case 0:
                    data = [{
                      key: 0,
                      value: '非店补'
                    }];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context5.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      userList(params).then(function _callee6(res) {
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context6.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.number = row.number;
      }

      userAdd(params).then(function _callee7(res) {
        return regeneratorRuntime.async(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context7.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this2 = this;

      //点击列表按钮回调
      if (btnIndex === 1) {
        this.$g.fun.startLoading();
        getMemberPwd({
          number: row.number
        }).then(function _callee8(res) {
          return regeneratorRuntime.async(function _callee8$(_context8) {
            while (1) {
              switch (_context8.prev = _context8.next) {
                case 0:
                  _this2.$g.fun.endLoading();

                  _this2.$alert('您的密码是' + res, '提示', {
                    confirmButtonText: '确定'
                  });

                case 2:
                case "end":
                  return _context8.stop();
              }
            }
          });
        });
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this3 = this;

      //点击列表头部按钮回调
      console.log(btnIndex, row);

      if (row.length <= 0) {
        this.$message({
          message: this.$t('请至少选择一个会员'),
          type: 'warning'
        });
        return;
      }

      var numbers = row.map(function (item) {
        return item.number;
      });

      if (btnIndex === 0) {
        this.$refs['mulGrade'].show(numbers, function () {
          _this3.table.reloadData();
        });
      } else if (btnIndex === 2) {
        this.$refs['memberMoney'].show(numbers[0], function () {
          _this3.table.reloadData();
        });
      } else if (btnIndex === 7) {
        clientLogin({
          number: numbers[0],
          tcode: this.info.code
        }).then(function _callee9(res) {
          var url;
          return regeneratorRuntime.async(function _callee9$(_context9) {
            while (1) {
              switch (_context9.prev = _context9.next) {
                case 0:
                  url = res.url;
                  window.open(url, "_blank");

                case 2:
                case "end":
                  return _context9.stop();
              }
            }
          });
        });
      } else if (btnIndex === 8) {
        //密码修改
        this.$refs['memberPwd'].show(row[0], function () {
          _this3.table.reloadData();
        });
      } else if (btnIndex === 9 || btnIndex === 10) {
        //充值中心
        var msg = '';
        var value = 0;

        if (btnIndex === 9) {
          msg = this.$t('批量设置为充值中心');
          value = 1;
        } else {
          msg = this.$t('批量撤销充值中心');
          value = 0;
        }

        this.confirm(msg + ',' + this.$t('确定要执行批量操作么？'), function () {
          memberBatchRecharge({
            number: numbers,
            is_recharge: value
          }).then(function _callee10(res) {
            return regeneratorRuntime.async(function _callee10$(_context10) {
              while (1) {
                switch (_context10.prev = _context10.next) {
                  case 0:
                    _this3.$message({
                      message: _this3.$t('批量设置成功'),
                      type: 'success'
                    });

                    _this3.table.reloadData();

                  case 2:
                  case "end":
                    return _context10.stop();
                }
              }
            });
          });
        });
      } else if (btnIndex === 11) {
        this.confirm(this.$t('会员删除,确定要执行批量操作么？'), function () {
          userDel({
            number: numbers
          }).then(function _callee11(res) {
            return regeneratorRuntime.async(function _callee11$(_context11) {
              while (1) {
                switch (_context11.prev = _context11.next) {
                  case 0:
                    _this3.$message({
                      message: _this3.$t('批量删除成功'),
                      type: 'success'
                    });

                    _this3.table.reloadData();

                  case 2:
                  case "end":
                    return _context11.stop();
                }
              }
            });
          });
        });
      } else if (btnIndex === 12) {
        this.$refs['mulLevel'].show(numbers, function () {
          _this3.table.reloadData();
        });
      } else if (btnIndex === 13) {
        this.$refs['mulStore'].show(numbers, function () {
          _this3.table.reloadData();
        });
      } else if (btnIndex === 17) {
        this.$refs['memberRegion'].show(numbers, function () {
          _this3.table.reloadData();
        });
      } else {
        var status = 0;
        var _msg = '';

        if (btnIndex === 1) {
          status = 6; //注销

          _msg = this.$t('选中会员注销');
        } else if (btnIndex === 3) {
          status = 3; //顶点位限制登录

          _msg = this.$t('选中会员以及其子点位会员全部限制登录');
        } else if (btnIndex === 4) {
          status = 2; //子点位限制登录

          _msg = this.$t('选中会员全部限制登录');
        } else if (btnIndex === 5) {
          status = 5; //冻结

          _msg = this.$t('选中会员账号被冻结，不参与定级结算');
        } else if (btnIndex === 6) {
          status = 1;
          _msg = this.$t('选中会员账号恢复正常状态');
        } else if (btnIndex === 14) {
          status = 7;
          _msg = this.$t('选中会员以及其子点位会员全部注销状态');
        } else if (btnIndex === 15) {
          status = 8;
          _msg = this.$t('选中会员以及其子点位会员全部冻结状态');
        } else if (btnIndex === 16) {
          status = 9;
          _msg = this.$t('选中会员以及其子点位会员全部恢复状态');
        }

        this.confirm(_msg + '，' + this.$t('确定要执行批量操作么？'), function () {
          memberMulUpdateState({
            number: numbers,
            status: status
          }).then(function _callee12(res) {
            return regeneratorRuntime.async(function _callee12$(_context12) {
              while (1) {
                switch (_context12.prev = _context12.next) {
                  case 0:
                    _this3.$message({
                      message: _this3.$t('批量设置成功'),
                      type: 'success'
                    });

                    _this3.table.reloadData();

                  case 2:
                  case "end":
                    return _context12.stop();
                }
              }
            });
          });
        });
      }
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    confirm: function confirm(msg, handle) {
      this.$confirm(msg, this.$t('提示'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(function () {
        if (handle) {
          handle();
        }
      }).catch(function () {});
    }
  }
};