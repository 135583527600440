var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ve-chart",
    {
      attrs: {
        else: "",
        data: _vm.data,
        settings: _vm.settings,
        grid: _vm.grid,
        colors: _vm.colors,
        events: _vm.events,
        "y-axis": _vm.yAxis,
        "x-axis": _vm.xAxis
      }
    },
    [
      _vm.data.rows && _vm.data.rows.length === 0
        ? _c("div", { staticClass: "data-empty" }, [
            _c("i", {
              staticClass: "el-icon-loading",
              staticStyle: { "margin-right": "5px" }
            }),
            _vm._v("没有数据 ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }