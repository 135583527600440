import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import pingJi from './bounsCond/pingJi';
import equalShare from './bounsCond/equalShare';
import agentBonus from './bounsCond/agentBonus';
import peiYu from './bounsCond/peiYu';
export default {
  components: {
    pingJi: pingJi,
    equalShare: equalShare,
    agentBonus: agentBonus,
    peiYu: peiYu
  },
  props: {
    config: {
      type: Object
    }
  },
  data: function data() {
    return {
      conditions: null,
      name: '',
      bonusType: '',
      dialogVisible: false,
      data: []
    };
  },
  mounted: function mounted() {},
  methods: {
    onClose: function onClose() {
      this.finish();
    },
    show: function show(row, finish) {
      this.row = row;
      this.finish = finish;
      this.conditions = row.conditions;
      this.name = row.name;
      this.bonusType = row.bonusType;
      this.dialogVisible = true;
    },
    clickSubmit: function clickSubmit() {
      this.dialogVisible = false;
      console.log('this.$refs[\'cond\'+this.bonusType]', this.$refs['cond' + this.bonusType], 'cond' + this.bonusType);
      var params = this.$refs['cond' + this.bonusType].getParams();
      this.row.conditions = params;
    }
  }
};