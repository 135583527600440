import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import localeMixin from '@/locales/mixin.js';
import store from "@/store/index";
import setting from '../../../../setting';
export default {
  mixins: [localeMixin],
  mounted: function mounted() {
    var _this = this;

    store.dispatch('d2admin/db/get', {
      dbName: 'sys',
      path: 'localLan' + setting.proId,
      defaultValue: {
        lan: 'zh-chs'
      },
      user: true
    }, {
      root: true
    }).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.$i18n.locale = res.lan;

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    onChangeLocale: function onChangeLocale(lan, com) {
      this.$i18n.locale = lan;
      store.dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'localLan' + setting.proId,
        value: {
          lan: lan
        },
        user: true
      }, {
        root: true
      });
    }
  }
};