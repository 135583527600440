import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import tempForm from '@/components/rw/tempForm/index';
import tempChart from '@/components/rw/tempChart/index';
import { expressImport, memberTakeAdd, memberTakeList } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        'del': false,
        'id': 'id_1000',
        'operateData': [{
          'icon': 'el-icon-search',
          'tag': 'search',
          'title': '搜索',
          'type': 'primary'
        }, {
          'icon': 'el-icon-position',
          'tag': 'export',
          'title': '导出',
          'type': 'primary'
        }],
        'searchData': [{
          'children': [{
            'field': [],
            'title': '全部',
            'type': 'button'
          }, {
            'field': [{
              'key': 'status',
              'value': '0'
            }],
            'title': '未处理',
            'type': 'button'
          }, {
            'field': [{
              'key': 'status',
              'value': '1'
            }],
            'title': '处理中',
            'type': 'button'
          }, {
            'field': [{
              'key': 'status',
              'value': '2'
            }],
            'title': '已汇出',
            'total': 0,
            'type': 'button'
          }, {
            'field': [{
              'key': 'status',
              'value': '-1'
            }],
            'title': '已拒绝',
            'total': 0,
            'type': 'button'
          }],
          'id': 0,
          'relation': false,
          'relationId': '',
          'remote': false,
          'remoteType': 0,
          'select': 0,
          'title': '申请状态',
          'type': 0,
          'remoteFunc': []
        }, {
          'children': [{
            'field': [],
            'title': '全部',
            'type': 'button'
          }, {
            'field': [],
            'title': '今天',
            'type': 'button'
          }, {
            'field': [],
            'title': '昨天',
            'type': 'button'
          }, {
            'field': [],
            'title': '最近7天',
            'type': 'button'
          }, {
            'field': [],
            'title': '本月',
            'type': 'button'
          }, {
            'field': [],
            'title': '本年',
            'type': 'button'
          }, {
            'field': [],
            'title': '自定义',
            'type': 'custom-datetime-range'
          }],
          'id': 1,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'select': 0,
          'title': '申请日期',
          'type': 1,
          'remoteFunc': ['modelConditionFunc_1']
        }, {
          'title': '审核日期',
          'select': 0,
          'type': 1,
          'id': 3,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'children': [{
            'type': 'button',
            'title': '全部',
            'field': []
          }, {
            'type': 'button',
            'title': '今天',
            'field': []
          }, {
            'type': 'button',
            'title': '昨天',
            'field': []
          }, {
            'type': 'button',
            'title': '最近7天',
            'field': []
          }, {
            'type': 'button',
            'title': '本月',
            'field': []
          }, {
            'type': 'button',
            'title': '本年',
            'field': []
          }, {
            'type': 'custom-datetime-range',
            'title': '自定义',
            'field': []
          }],
          'remoteFunc': ['modelConditionFunc_3']
        }, {
          'children': [{
            'field': [{
              'desc': '提现金额',
              'key': 'money',
              'value': ''
            }],
            'operator': [{
              'key': 'gte',
              'value': '>='
            }, {
              'key': 'lte',
              'value': '<='
            }, {
              'key': 'gt',
              'value': '>'
            }, {
              'key': 'lt',
              'value': '<'
            }, {
              'key': 'eq',
              'value': '='
            }],
            'placeholder': '请输入',
            'type': 'input-mul-complex'
          }, {
            'field': [{
              'desc': '会员编号',
              'key': 'number'
            }, {
              'desc': '操作员姓名',
              'key': 'op_name',
              'value': ''
            }, {
              'desc': '操作员编号',
              'key': 'op',
              'value': ''
            }, {
              'desc': '顶点编号',
              'key': 'top_number',
              'value': ''
            }],
            'operator': [],
            'placeholder': '请输入',
            'type': 'input-complex'
          }],
          'id': 2,
          'relation': false,
          'relationId': '',
          'remote': false,
          'remoteType': 0,
          'select': 0,
          'title': '按条件',
          'type': 2,
          'remoteFunc': []
        }],
        'type': 'searchTemplate'
      },
      tableModel_1001: {
        'border': true,
        'del': false,
        'headerButton': [{
          'id': '',
          'tag': 'default',
          'title': '账号错误',
          'type': 'primary'
        }, {
          'id': '',
          'tag': 'default',
          'title': '开始处理',
          'type': 'primary'
        }, {
          'id': '',
          'tag': 'default',
          'title': '已汇出',
          'type': 'primary'
        }],
        'id': 'id_1001',
        'isGroup': false,
        'isTree': false,
        'rowButton': [{
          'id': '',
          'tag': 'detail',
          'title': '详情',
          'type': null
        }],
        'showPagination': true,
        'showSelection': true,
        'showSummary': false,
        'stripe': true,
        'tableAutocompleteProps': {},
        'tableCascaderProps': {},
        'tableFileProps': {},
        'tableModel': [{
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': 'ID',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': 80,
          'prop': 'id'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '会员编号',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'number'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '会员电话',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 't_user.phone'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '会员姓名',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 't_user.real_name'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '状态',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'select',
          'width': '100',
          'prop': 'status'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '提现金额',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'money'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '手续费',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'tax'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '实际提现',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'real_take'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '银行名称',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': 160,
          'prop': 'bank_code'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '支行名称',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': 160,
          'prop': 'bank_branch'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '卡号信息',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': 160,
          'prop': 'bank_no'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '申请时间',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '150',
          'prop': 'apply_time'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '审核时间',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '150',
          'prop': 'verify_time'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '汇款时间',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '150',
          'prop': 'send_time'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '申请类型',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'select',
          'width': '100',
          'prop': 'type'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '备注信息',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': 160,
          'prop': 'remark'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '省',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'province'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '市',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'city'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '区',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'district'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '用户ip',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'ip'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '操作员',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'op'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '操作员姓名',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'op_name'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '操作员ip',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'op_ip'
        }],
        'tableName': 't_withdrawal',
        'tableRadioProps': {},
        'tableSelectProps': {
          'status': {
            'data': [{
              'key': -1,
              'value': '账号错误'
            }, {
              'key': 0,
              'value': '未处理'
            }, {
              'key': 1,
              'value': '准备提交'
            }, {
              'key': 2,
              'value': '已汇出'
            }],
            'id': 0,
            'multiple': 0,
            'relation': false,
            'relationProps': [],
            'remote': false
          },
          'type': {
            'data': [{
              'key': 1,
              'value': '正常申请'
            }],
            'id': 1,
            'multiple': 0,
            'relation': false,
            'relationProps': [],
            'remote': false
          }
        },
        'tableSwitchProps': {},
        'title': '提现日志',
        'type': 'tableTemplate'
      },
      sum: {
        sum_handle_result: 0,
        sum_money_result: 0,
        sum_tax_result: 0
      },
      remoteFunc: {
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: 'apply_time',
            value: []
          }], [{
            key: 'apply_time',
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: 'apply_time',
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: 'apply_time',
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: 'apply_time',
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: 'apply_time',
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: 'apply_time',
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: 'verify_time',
            value: []
          }], [{
            key: 'verify_time',
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: 'verify_time',
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: 'verify_time',
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: 'verify_time',
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: 'verify_time',
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: 'verify_time',
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var _this2 = this;

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      memberTakeList(params).then(function _callee(res) {
        var i;
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this2.sum = res.sum;

                for (i in res.rows) {
                  res.rows[i].real_take = res.rows[i].money - res.rows[i].tax;
                }

                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 3:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this3 = this;

      //点击列表头部按钮回调
      if (row.length <= 0) {
        this.$message({
          message: '请至少选择一条申请',
          type: 'warning'
        });
        return;
      }

      var type = '';

      if (btnIndex === 0) {
        type = 'reject';
      } else if (btnIndex === 1) {
        type = 'agree';
      } else if (btnIndex === 2) {
        type = 'complete';
      }

      var ids = row.map(function (item) {
        return item.id;
      });
      this.$prompt(this.$t('请输入备注信息后提交操作'), this.$t('提示'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        inputValue: this.tableModel_1001.headerButton[btnIndex].title
      }).then(function (_ref) {
        var value = _ref.value;
        memberTakeAdd({
          id: ids,
          type: type,
          remark: value
        }).then(function _callee2(res) {
          return regeneratorRuntime.async(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _this3.$message({
                    message: '操作成功',
                    type: 'success'
                  });

                  _this3.table.reloadData();

                case 2:
                case "end":
                  return _context2.stop();
              }
            }
          });
        });
      }).catch(function () {});
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};