var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.row ? _vm.row.name + "条件配置" : "",
        visible: _vm.dialogVisible,
        width: "900px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "destroy-on-close": true,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _vm.row
        ? _c(
            "div",
            {
              staticStyle: {
                "max-height": "70vh",
                "overflow-y": "auto",
                padding: "15px 30px",
                "background-color": "#f6f6f6"
              }
            },
            [
              _c(
                "el-card",
                { staticClass: "row-card", attrs: { shadow: "never" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        "label-width": "150px",
                        size: "mini",
                        "label-position": "top"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖励代数" } },
                        [
                          _c("el-input-number", {
                            attrs: { size: "mini", min: 0, max: 100 },
                            model: {
                              value: _vm.conditions.layer,
                              callback: function($$v) {
                                _vm.$set(_vm.conditions, "layer", $$v)
                              },
                              expression: "conditions.layer"
                            }
                          }),
                          _c(
                            "el-tag",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { size: "mini", type: "primary" }
                            },
                            [_vm._v("0：不限制代数，直到所有人遍历完")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "计算类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.conditions.bonusMode,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "bonusMode", $$v)
                                },
                                expression: "conditions.bonusMode"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("比率")
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("金额")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单是否会对本人产生收益" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.conditions.orderGiveSelf,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "orderGiveSelf", $$v)
                                },
                                expression: "conditions.orderGiveSelf"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否")
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖励人是否要大于下单人等级" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.conditions.greaterOrderGrade,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.conditions,
                                    "greaterOrderGrade",
                                    $$v
                                  )
                                },
                                expression: "conditions.greaterOrderGrade"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否")
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "计算方式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.conditions.computeType,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "computeType", $$v)
                                },
                                expression: "conditions.computeType"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("非级差计算")
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("级差计算")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              "计算时使用的报单人等级（报单人如果升级了，使用报单人之前等级还是之后等级）"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.conditions.bdGradeType,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "bdGradeType", $$v)
                                },
                                expression: "conditions.bdGradeType"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("报单前等级")
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("报单后等级")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生效的订单类型" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.conditions.orderTypes,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "orderTypes", $$v)
                                },
                                expression: "conditions.orderTypes"
                              }
                            },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: 1, name: "type" } },
                                [_vm._v("注册单")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: 2, name: "type" } },
                                [_vm._v("复销单")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: 3, name: "type" } },
                                [_vm._v("升级单")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "影响奖金因素" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.conditions.factor,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "factor", $$v)
                                },
                                expression: "conditions.factor"
                              }
                            },
                            _vm._l(_vm.factorList, function(item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.tag,
                                  attrs: { label: item.tag },
                                  on: { change: _vm.onFactorChange }
                                },
                                [_vm._v(_vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showAfterGrade
                        ? _c(
                            "el-card",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "30px",
                                    "padding-top": "10px",
                                    "padding-bottom": "10px"
                                  }
                                },
                                [_vm._v(" 团队业绩属性设置 ")]
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 11 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label:
                                              "是否按照奖励会员定级时间开始结算业绩"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                "true-label": 1,
                                                "false-label": 0
                                              },
                                              model: {
                                                value:
                                                  _vm.conditions.isAfterGrade,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.conditions,
                                                    "isAfterGrade",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "conditions.isAfterGrade"
                                              }
                                            },
                                            [_vm._v("是")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 11, offset: 2 } },
                                    [
                                      _vm.conditions.isAfterGrade
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "奖励会员的最低等级"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value:
                                                      _vm.conditions.afterGrade,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.conditions,
                                                        "afterGrade",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "conditions.afterGrade"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.config.gradeData,
                                                  function(item) {
                                                    return _c("el-option", {
                                                      key: item.tag,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.tag
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "团队业绩是否包含本人业绩"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: _vm.conditions.povIsSelf,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.conditions,
                                                    "povIsSelf",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "conditions.povIsSelf"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("否")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("是")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "团队业绩方式" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100px" },
                                              attrs: { size: "mini" },
                                              model: {
                                                value: _vm.conditions.povOrder,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.conditions,
                                                    "povOrder",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "conditions.povOrder"
                                              }
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: 0,
                                                  label: "业绩"
                                                }
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  value: 1,
                                                  label: "单数"
                                                }
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  value: 2,
                                                  label: "产品数量"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "团队业绩订单类型" }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "220px" },
                                              attrs: {
                                                size: "mini",
                                                multiple: ""
                                              },
                                              model: {
                                                value: _vm.conditions.povType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.conditions,
                                                    "povType",
                                                    $$v
                                                  )
                                                },
                                                expression: "conditions.povType"
                                              }
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: 1,
                                                  label: "注册"
                                                }
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  value: 2,
                                                  label: "重购"
                                                }
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  value: 3,
                                                  label: "升级"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖金影响因素" } },
                        [
                          _vm._l(_vm.conditions.factorClassify, function(
                            item,
                            index
                          ) {
                            return _c(
                              "el-card",
                              {
                                staticStyle: { "margin-bottom": "5px" },
                                attrs: { shadow: "never" }
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "header" }, slot: "header" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.factor.name))
                                    ])
                                  ]
                                ),
                                item.factor.type === "pv"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            model: {
                                              value: item.select,
                                              callback: function($$v) {
                                                _vm.$set(item, "select", $$v)
                                              },
                                              expression: "item.select"
                                            }
                                          },
                                          [
                                            _vm._l(item.children, function(
                                              subItem,
                                              subIndex
                                            ) {
                                              return _c(
                                                "el-checkbox",
                                                {
                                                  key: subItem.tag,
                                                  attrs: { label: subItem.tag }
                                                },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      size: "mini",
                                                      min: 0
                                                    },
                                                    model: {
                                                      value: subItem.name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          subItem,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "subItem.name"
                                                    }
                                                  }),
                                                  _c("el-button", {
                                                    staticStyle: {
                                                      "margin-left": "5px"
                                                    },
                                                    attrs: {
                                                      circle: "",
                                                      size: "mini",
                                                      icon: "el-icon-delete"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.clickDelFactorClassify(
                                                          item.children,
                                                          subIndex
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            }),
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px"
                                                },
                                                attrs: {
                                                  type: "text",
                                                  size: "mini"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.clickAddFactorPvChildren(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" 添加 ")]
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            model: {
                                              value: item.select,
                                              callback: function($$v) {
                                                _vm.$set(item, "select", $$v)
                                              },
                                              expression: "item.select"
                                            }
                                          },
                                          _vm._l(item.children, function(
                                            subItem
                                          ) {
                                            return _c(
                                              "el-checkbox",
                                              {
                                                key: subItem.tag,
                                                attrs: { label: subItem.tag }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(subItem.name) + " "
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                              ]
                            )
                          }),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: { click: _vm.resetFactorData }
                                },
                                [_vm._v("生成奖励配置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "success" },
                                  on: { click: _vm.clearFactorData }
                                },
                                [_vm._v("重置")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-card",
                            { attrs: { shadow: "never" } },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "header" }, slot: "header" },
                                [_vm._v("奖金奖励配置")]
                              ),
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.conditions.factorClassifyBonus,
                                    "span-method": _vm.objectSpanMethod,
                                    border: "",
                                    size: "mini"
                                  }
                                },
                                _vm._l(
                                  _vm.conditions.factorClassifyBonus[0],
                                  function(item, index) {
                                    return _c("el-table-column", {
                                      attrs: {
                                        label: _vm.factorName(
                                          item,
                                          index,
                                          _vm.conditions
                                        )
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                index <
                                                _vm.conditions
                                                  .factorClassifyBonus[0]
                                                  .length -
                                                  1
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "65px",
                                                              "margin-right":
                                                                "10px"
                                                            },
                                                            attrs: {
                                                              size: "mini"
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row[index]
                                                                  .classifyOp,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  scope.row[
                                                                    index
                                                                  ],
                                                                  "classifyOp",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "scope.row[index].classifyOp"
                                                            }
                                                          },
                                                          [
                                                            _c("el-option", {
                                                              attrs: {
                                                                label: ">=",
                                                                value: ">="
                                                              }
                                                            }),
                                                            _c("el-option", {
                                                              attrs: {
                                                                label: "=",
                                                                value: "="
                                                              }
                                                            }),
                                                            _c("el-option", {
                                                              attrs: {
                                                                label: "<=",
                                                                value: "<="
                                                              }
                                                            }),
                                                            _c("el-option", {
                                                              attrs: {
                                                                label: ">",
                                                                value: ">"
                                                              }
                                                            }),
                                                            _c("el-option", {
                                                              attrs: {
                                                                label: "<",
                                                                value: "<"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row[index]
                                                                .classifyName
                                                            )
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      [
                                                        _c("el-input-number", {
                                                          attrs: {
                                                            size: "mini",
                                                            min: 0
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row[index]
                                                                .classifyValue,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row[
                                                                  index
                                                                ],
                                                                "classifyValue",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row[index].classifyValue"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "获得条件" } },
                        [
                          _c(
                            "el-card",
                            { staticStyle: { "margin-top": "10px" } },
                            [
                              _c("condition-creator", {
                                ref: "creator",
                                attrs: {
                                  "grade-list": _vm.config.gradeData,
                                  "level-list": _vm.config.levelData
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v(_vm._s("保 存"))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }