import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.parse-int";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    conditions: {
      type: Object
    },
    gradeData: {
      type: Array
    },
    bonusData: {
      type: Array
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        bonusMode: 0,
        isTighten: 1,
        //是否紧缩
        bonusTag: 0,
        selectGrade: [],
        factorList: [],
        isAfterGrade: 0
      },
      giveGrade: []
    };
  },
  mounted: function mounted() {
    if (this.conditions) {
      for (var key in this.form) {
        this.form[key] = this.conditions[key];
      }
    }

    this.giveGrade = this.$tempModel.deepCopy(this.gradeData);
    this.giveGrade.push({
      tag: -1,
      name: '公司'
    });

    for (var i in this.giveGrade) {
      this.giveGrade[i].tag = parseInt(this.giveGrade[i].tag);
    }
  },
  methods: {
    getGradeByTag: function getGradeByTag(tag) {
      for (var i in this.gradeData) {
        if (this.gradeData[i].tag == tag) {
          return this.gradeData[i];
        }
      }

      return null;
    },
    createFactor: function createFactor() {
      var tmp = [];

      for (var i in this.form.selectGrade) {
        var item = this.getGradeByTag(this.form.selectGrade[i]);
        tmp.push({
          grade: item.tag,
          gradeName: item.name,
          giveGrade: [],
          specialGrade: [],
          giveMaxMoney: '',
          giveRate: 0,
          rateList: []
        });
      }

      this.form.factorList = tmp;
    },
    clearFactorData: function clearFactorData() {
      this.form.selectGrade = [];
      this.form.factorList = [];
    },
    clickAddLayer: function clickAddLayer(index) {
      this.form.factorList[index].rateList.push({
        rate: 0
      });
    },
    clickDelLayer: function clickDelLayer(index, subIndex) {
      var tmp = this.form.factorList[index].rateList;
      tmp.splice(subIndex, 1);
      this.form.factorList[index].rateList = tmp;
    },
    getParams: function getParams() {
      var params = this.form;
      return this.$tempModel.deepCopy(params);
    }
  }
};