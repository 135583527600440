var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { attrs: { shadow: "never" } },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "160px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "是否删除订单" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.form.tagOrder,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "tagOrder", $$v)
                          },
                          expression: "form.tagOrder"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c("el-tag", { staticClass: "desc" }, [
                        _vm._v(_vm._s("| " + _vm.form.tagOrderDesc))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "网站前台开关" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择活动区域" },
                          model: {
                            value: _vm.form.tagWeb,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "tagWeb", $$v)
                            },
                            expression: "form.tagWeb"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "常关", value: 0 }
                          }),
                          _c("el-option", {
                            attrs: { label: "常开", value: 1 }
                          }),
                          _c("el-option", {
                            attrs: { label: "指定时间关闭", value: 2 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c("el-tag", { staticClass: "desc" }, [
                        _vm._v(_vm._s("| " + _vm.form.tagWebDesc))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否开放注册" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.form.tagOpenReg,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "tagOpenReg", $$v)
                          },
                          expression: "form.tagOpenReg"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c("el-tag", { staticClass: "desc" }, [
                        _vm._v(_vm._s("| " + _vm.form.tagOpenRegDesc))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.form.tagWeb === 2
            ? _c(
                "el-form-item",
                { attrs: { label: "网站自动关闭时间" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "datetime",
                              placeholder: ""
                            },
                            model: {
                              value: _vm.form.startDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "startDate", $$v)
                              },
                              expression: "form.startDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-tag", { staticClass: "desc" }, [
                            _vm._v(_vm._s("| " + _vm.form.startDateDesc))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.form.tagWeb === 2
            ? _c(
                "el-form-item",
                { attrs: { label: "网站自动打开时间" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "datetime",
                              placeholder: ""
                            },
                            model: {
                              value: _vm.form.endDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "endDate", $$v)
                              },
                              expression: "form.endDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-tag", { staticClass: "desc" }, [
                            _vm._v(_vm._s("| " + _vm.form.endDateDesc))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.clickSubmit }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }