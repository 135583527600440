import "core-js/modules/es.array.index-of";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: ['conditionList', 'data', 'onClickDelItem', 'onClickAddCond', 'onClickResetParams', 'onCondChange', 'index', 'gradeList', 'levelList'],
  name: 'conditionItemCell',
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {
    hasPeriod: function hasPeriod() {
      return this.data.hasOwnProperty('period');
    },
    hasType: function hasType() {
      return this.data.hasOwnProperty('type');
    },
    hasOrder: function hasOrder() {
      return this.data.hasOwnProperty('order');
    },
    hasMySelf: function hasMySelf() {
      return this.data.hasOwnProperty('myself');
    },
    hasGrade: function hasGrade(params) {
      return params.hasOwnProperty('grade');
    },
    hasLevel: function hasLevel(params) {
      return params.hasOwnProperty('level');
    },
    clickAddCondition: function clickAddCondition() {
      this.onClickAddCond(this.index, this.data);
    },
    clickDelItem: function clickDelItem() {
      this.onClickDelItem(this.index, this.data);
    },
    clickResetParams: function clickResetParams() {
      this.onClickResetParams(this.index, this.data);
    },
    condChange: function condChange(value) {
      this.onCondChange(this.index, value);
    },
    orderChange: function orderChange(value) {
      var params = this.$tempModel.deepCopy(this.data.params);
      var names = ['pv', '单', '品'];

      for (var i in params) {
        if (names.indexOf(params[i].unit) >= 0) {
          params[i].unit = names[value];
        }
      }

      this.data.params = params;
    }
  }
};