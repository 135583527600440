var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      [
        _c(
          "el-card",
          [
            _c(
              "div",
              { attrs: { slot: "header" }, slot: "header" },
              [
                _c(
                  "el-row",
                  { attrs: { type: "flex", justify: "space-between" } },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("业绩总览")))])]
                )
              ],
              1
            ),
            _c(
              "el-row",
              { staticStyle: { "margin-top": "10px" } },
              _vm._l(_vm.numberListTotal, function(item) {
                return _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-card",
                      {
                        staticStyle: {
                          display: "flex",
                          width: "96%",
                          "margin-left": "2%",
                          "align-items": "center",
                          position: "relative"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "circle-icon",
                                style: { backgroundColor: item.color }
                              },
                              [
                                _c("i", {
                                  class: item.icon,
                                  staticStyle: {
                                    "font-size": "30px",
                                    color: "white"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "column",
                                  "margin-left": "10px"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#ff5c87",
                                      "font-size": "30px"
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          (item.unit ? item.unit : "") +
                                            item.value
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#767676",
                                      "font-size": "13px"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          marginLeft: item.unit ? "10px" : "0"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.title))]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              position: "absolute",
                              right: "5px",
                              top: "5px"
                            },
                            attrs: { size: "mini" }
                          },
                          [_vm._v(_vm._s(_vm.$t("总")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        ),
        _c(
          "el-card",
          [
            _c(
              "div",
              { attrs: { slot: "header" }, slot: "header" },
              [
                _c(
                  "el-row",
                  { attrs: { type: "flex", justify: "space-between" } },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("当期业绩")))]),
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: _vm.$t("请选择") },
                        on: { change: _vm.changeExpectNum },
                        model: {
                          value: _vm.curExpectNum,
                          callback: function($$v) {
                            _vm.curExpectNum = $$v
                          },
                          expression: "curExpectNum"
                        }
                      },
                      _vm._l(_vm.expectList, function(item) {
                        return _c("el-option", {
                          key: item.key,
                          attrs: { label: item.value, value: item.key }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-row",
              { staticStyle: { "margin-top": "10px" } },
              _vm._l(_vm.numberList, function(item) {
                return _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-card",
                      {
                        staticStyle: {
                          display: "flex",
                          width: "96%",
                          "margin-left": "2%",
                          "align-items": "center"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "circle-icon",
                                style: { backgroundColor: item.color }
                              },
                              [
                                _c("i", {
                                  class: item.icon,
                                  staticStyle: {
                                    "font-size": "30px",
                                    color: "white"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "column",
                                  "margin-left": "10px"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#ff5c87",
                                      "font-size": "30px"
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          (item.unit ? item.unit : "") +
                                            item.value
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#767676",
                                      "font-size": "13px"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          marginLeft: item.unit ? "10px" : "0"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.title))]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        ),
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 18 } },
                  [
                    _c(
                      "el-card",
                      { staticStyle: { width: "99%" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("新增业绩")))])]
                        ),
                        _c(
                          "temp-chart",
                          _vm._b(
                            {
                              ref: "ref_1000",
                              attrs: { "remote-func": _vm.remoteFunc }
                            },
                            "temp-chart",
                            _vm.chartModel_1000,
                            false
                          )
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-card",
                      { attrs: { shadow: "never" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("订单类型")))])]
                        ),
                        _c(
                          "temp-chart",
                          _vm._b(
                            {
                              ref: "ref_1001",
                              attrs: { "remote-func": _vm.remoteFunc }
                            },
                            "temp-chart",
                            _vm.chartModel_1001,
                            false
                          )
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 11 } },
                  [
                    _c(
                      "el-card",
                      { staticClass: "rank" },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("各等级人数列表")))
                            ])
                          ]
                        ),
                        _c(
                          "temp-table",
                          _vm._b(
                            {
                              ref: "ref_1004",
                              attrs: {
                                "remote-func": _vm.remoteFunc,
                                "req-table-data": _vm.reqTableData1
                              }
                            },
                            "temp-table",
                            _vm.tableModel_1004,
                            false
                          )
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 11 } },
                  [
                    _c(
                      "el-card",
                      { staticClass: "rank" },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("商品销售排行列表")))
                            ])
                          ]
                        ),
                        _c(
                          "temp-table",
                          _vm._b(
                            {
                              ref: "ref_1002",
                              attrs: {
                                "remote-func": _vm.remoteFunc,
                                "req-table-data": _vm.reqTableData3
                              }
                            },
                            "temp-table",
                            _vm.tableModel_1002,
                            false
                          )
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }