import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberMove, memberPwdModify, memberRepairMoney } from '../../../../api/apis';
import aes from "@/plugin/aes.js";
import md5 from 'js-md5';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      tagList: [{
        key: 0,
        value: this.$t('登录密码')
      }, {
        key: 1,
        value: this.$t('支付密码')
      }],
      form: {
        number: '',
        pwd: '',
        tag: 0
      },
      rules: {
        pwd: [{
          require: true,
          message: this.$t('请输入新密码'),
          trigger: 'blur'
        }]
      },
      pwdKey: 'PfZJcrksh2Hz4meL11SxSVNP1Kh5bYNK5gHeIZ8Q5FtsGLX4Ymc6lWJxzDpQX7INMwbb9Mhy0oApLXlLgHSGeIvo8Skij2A26Rwkk9Ntdpww1wRLBCLvJFf76t91VCIzFGdSIedGvxnyZLQUyjF7UZDckX83T7BWRElLxwf453YkTxgwa2gFNOwd1U69SjuWAkSAlRgiVonQI1GEOEblpQmXdMuR0XxPtkzaZHNvYm0TDbFMwY3MmzaeZyeF0NJgohCs9nnVucyFOErUYlZnXsm9TNLf0a896tCXJ6UHCK9EllTxywhHWevUl8vvMVQppZIukgVKu3dRnBGOYav91mzKut6rHOJQvfAmdyRRGn1lD5ABDi1lrEjl6sANbAE36cFTPfYVZUeQ18pA5DuSyF7glSo5GtvFZJYJMkDCy5URks4uscGvbfMvrm6vOxGsoe8gYZil8BJkGRL2rFoCm9VDMQA6aD5aGNXy68IP5dLORw4kh6TtVX5ATEIYU8KS'
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(row, finish) {
      this.dialogVisible = true;
      this.form.number = row.number;
      this.finish = finish;
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      memberPwdModify({
        number: this.form.number,
        pwd: aes.encrypt(this.form.pwd),
        tag: this.form.tag
      }).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.$message({
                  message: _this.$t('修改成功'),
                  type: 'success'
                });

                _this.dialogVisible = false;

                _this.finish();

              case 3:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    }
  }
};