import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
import { Message, MessageBox } from 'element-ui';
import util from '@/libs/util.js';
import router from '@/router';
import { accountLogin } from '@/api/apis';
import setting from '@/setting';
import routerSwitch from '@/router/routerSwitch';
import i18n from '@/i18n';
export default {
  namespaced: true,
  actions: {
    /**
     * @description 登录
     * @param {Object} context
     * @param {Object} payload username {String} 用户账号
     * @param {Object} payload password {String} 密码
     * @param {Object} payload route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    login: function login(_ref) {
      var dispatch = _ref.dispatch;

      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref2$account = _ref2.account,
          account = _ref2$account === void 0 ? '' : _ref2$account,
          _ref2$pwd = _ref2.pwd,
          pwd = _ref2$pwd === void 0 ? '' : _ref2$pwd,
          _ref2$code = _ref2.code,
          code = _ref2$code === void 0 ? '' : _ref2$code;

      return new Promise(function (resolve, reject) {
        // 开始请求登录接口
        accountLogin({
          account: account,
          pwd: pwd,
          tcode: code
        }).then(function _callee(res) {
          return regeneratorRuntime.async(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  console.log('login', res); // 设置 cookie 一定要存 uuid 和 token 两个 cookie
                  // 整个系统依赖这两个数据进行校验和存储
                  // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
                  // token 代表用户当前登录状态 建议在网络请求中携带 token
                  // 如有必要 token 需要定时更新，默认保存一天

                  util.cookies.set('uuid', res.id);
                  util.cookies.set('token', res.token, {
                    expires: setting.loginConfig.tokenExceedMinute
                  });
                  console.log('bbbbbbb', res.rules_ids); // 设置 vuex 用户信息

                  _context.next = 6;
                  return regeneratorRuntime.awrap(dispatch('d2admin/user/set', {
                    account: res.account,
                    real_name: res.real_name,
                    roles: res.roles,
                    last_time: res.last_time,
                    login_count: res.login_count,
                    level: res.level,
                    status: res.status,
                    t_system_role: res.t_system_role,
                    pwd: pwd,
                    rules_ids: res.rules_ids
                  }, {
                    root: true
                  }));

                case 6:
                  _context.next = 8;
                  return regeneratorRuntime.awrap(dispatch('load'));

                case 8:
                  // 结束
                  resolve();

                case 9:
                case "end":
                  return _context.stop();
              }
            }
          });
        }).catch(function (err) {
          reject(err);
        });
      });
    },

    /**
     * @description 注销用户并返回登录页面
     * @param {Object} context
     * @param {Object} payload confirm {Boolean} 是否需要确认
     */
    logout: function logout(_ref3) {
      var commit = _ref3.commit,
          dispatch = _ref3.dispatch;

      var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref4$confirm = _ref4.confirm,
          confirm = _ref4$confirm === void 0 ? false : _ref4$confirm;

      /**
       * @description 注销
       */
      function logout() {
        return regeneratorRuntime.async(function logout$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                // 删除cookie
                util.cookies.remove('token');
                util.cookies.remove('uuid'); // util.cookies.remove('permissions')
                // 清空 vuex 用户信息

                _context2.next = 4;
                return regeneratorRuntime.awrap(dispatch('d2admin/user/set', {}, {
                  root: true
                }));

              case 4:
                // 跳转路由
                router.push({
                  name: 'login'
                });

              case 5:
              case "end":
                return _context2.stop();
            }
          }
        });
      } // 判断是否需要确认


      if (confirm) {
        commit('d2admin/gray/set', true, {
          root: true
        });
        MessageBox.confirm(i18n.tc('确定要注销当前用户吗'), i18n.tc('注销用户'), {
          type: 'warning'
        }).then(function () {
          routerSwitch.isFetchPermissionInfo = false;
          console.log('111111', routerSwitch.isFetchPermissionInfo);
          commit('d2admin/gray/set', false, {
            root: true
          });
          logout();
          location.reload();
        }).catch(function () {
          commit('d2admin/gray/set', false, {
            root: true
          });
          Message({
            message: '取消注销操作'
          });
        });
      } else {
        logout();
      }
    },

    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} context
     */
    load: function load(_ref5) {
      var dispatch = _ref5.dispatch;
      return new Promise(function _callee2(resolve) {
        return regeneratorRuntime.async(function _callee2$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return regeneratorRuntime.awrap(dispatch('common/system/load', null, {
                  root: true
                }));

              case 2:
                _context3.next = 4;
                return regeneratorRuntime.awrap(dispatch('d2admin/user/load', null, {
                  root: true
                }));

              case 4:
                _context3.next = 6;
                return regeneratorRuntime.awrap(dispatch('d2admin/theme/load', null, {
                  root: true
                }));

              case 6:
                _context3.next = 8;
                return regeneratorRuntime.awrap(dispatch('d2admin/transition/load', null, {
                  root: true
                }));

              case 8:
                _context3.next = 10;
                return regeneratorRuntime.awrap(dispatch('d2admin/page/openedLoad', null, {
                  root: true
                }));

              case 10:
                _context3.next = 12;
                return regeneratorRuntime.awrap(dispatch('d2admin/menu/asideCollapseLoad', null, {
                  root: true
                }));

              case 12:
                _context3.next = 14;
                return regeneratorRuntime.awrap(dispatch('d2admin/size/load', null, {
                  root: true
                }));

              case 14:
                _context3.next = 16;
                return regeneratorRuntime.awrap(dispatch('d2admin/color/load', null, {
                  root: true
                }));

              case 16:
                // end
                resolve();

              case 17:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    }
  }
};