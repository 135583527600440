var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-card",
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(" 注册选项配置 ")
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.clickAdd }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "tableData",
                  staticStyle: { width: "100%", marginTop: "15px" },
                  attrs: {
                    size: "mini",
                    border: "",
                    "row-key": "key",
                    data: _vm.tableData
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "key", label: "KEY" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "项目" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "regShow", label: "注册可见项" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": 1, "false-label": 0 },
                              model: {
                                value: scope.row.regShow,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "regShow", $$v)
                                },
                                expression: "scope.row.regShow"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "regRequire", label: "注册必选项" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: {
                                "true-label": 1,
                                "false-label": 0,
                                disabled: !scope.row.regShow
                              },
                              model: {
                                value: scope.row.regRequire,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "regRequire", $$v)
                                },
                                expression: "scope.row.regRequire"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v("` "),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "verify", fixed: "right", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickEdit(scope.row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickDel(scope.row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    float: "right",
                    "margin-top": "20px",
                    "margin-bottom": "20px"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.clickSubmit }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("temp-add", {
        ref: "add",
        attrs: {
          title: "配置",
          "table-model": _vm.tableModel_1000.tableModel,
          "select-props": _vm.tableModel_1000.tableSelectProps,
          "switch-props": _vm.tableModel_1000.tableSwitchProps,
          "cascader-props": _vm.tableModel_1000.tableCascaderProps,
          "radio-props": _vm.tableModel_1000.tableRadioProps,
          "table-group-props": _vm.tableModel_1000.tableGroupProps,
          "on-submit": _vm.onReqAdd
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }