import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import { areaList, expressImport, orderAdd, orderCancel, orderInvoice, orderList, productList, userList } from '../../../../api/apis';
export default {
  name: 'orderManage',
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch
  },
  data: function data() {
    var _this = this;

    return {
      total_price: 0,
      activeNames: 'search',
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": true,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "id": "118",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }, {
          "icon": "el-icon-bottom-right",
          "id": "117",
          "tag": "import",
          "title": "物流单号导入",
          "type": "success"
        }],
        "searchData": [{
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "0"
            }],
            "title": "未出库",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "1"
            }],
            "title": "部分出库",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "2"
            }],
            "title": "全部出库",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "3"
            }],
            "title": "部分发货",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "4"
            }],
            "title": "全部发货",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "5"
            }],
            "title": "完成",
            "total": 0,
            "type": "button"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "订单状态",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [{
              "key": "paid",
              "value": "1"
            }],
            "title": "已支付",
            "type": "button"
          }, {
            "field": [{
              "key": "paid",
              "value": "0"
            }],
            "title": "未支付",
            "type": "button"
          }],
          "id": 6,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "支付状态",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "订单日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_3"]
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 14,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "支付日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_14"]
        }, {
          "children": [{
            "field": [{
              "desc": "订单号",
              "key": "order_id",
              "value": ""
            }, {
              "desc": "会员编号",
              "key": "number",
              "value": ""
            }, {
              "desc": "推荐人编号",
              "key": "direct",
              "value": ""
            }, {
              "desc": "收货人姓名",
              "key": "real_name",
              "value": ""
            }, {
              "desc": "收货人电话",
              "key": "user_phone",
              "value": ""
            }, {
              "desc": "报单人编号",
              "key": "store_number",
              "value": ""
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "订单金额",
              "key": "total_price"
            }, {
              "desc": "产品数量",
              "key": "total_num",
              "value": ""
            }, {
              "desc": "支付期数",
              "key": "pay_expect_num",
              "value": ""
            }],
            "operator": [{
              "key": "gte",
              "value": ">="
            }, {
              "key": "lte",
              "value": "<="
            }, {
              "key": "gt",
              "value": ">"
            }, {
              "key": "lt",
              "value": "<"
            }, {
              "key": "eq",
              "value": "="
            }, {
              "key": "ne",
              "value": "!="
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "顶点编号",
              "key": "top_number"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "订单号",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [{
              "desc": "注册单",
              "key": "order_type",
              "value": "1"
            }, {
              "desc": "重购单",
              "key": "order_type",
              "value": "2"
            }, {
              "desc": "升级单",
              "key": "order_type",
              "value": "3"
            }],
            "multiple": true,
            "type": "select"
          }],
          "id": 11,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "订单类型",
          "type": 3,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [{
              "desc": "省",
              "key": "address_province",
              "value": ""
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "市",
              "key": "address_city"
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "区",
              "key": "address_district"
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }],
          "id": 13,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按地区",
          "type": 2,
          "remoteFunc": []
        }, {
          "title": "取货方式",
          "select": 0,
          "type": 0,
          "id": 15,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "邮寄",
            "field": [{
              "key": "take_type",
              "value": "0"
            }]
          }, {
            "type": "button",
            "title": "自提",
            "field": [{
              "key": "take_type",
              "value": "1"
            }]
          }],
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [{
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "生成发票",
          "type": null
        }],
        "id": "id_1001",
        "isTree": false,
        "rowButton": [{
          "icon": "",
          "id": "",
          "tag": "edit",
          "title": "变更地址",
          "type": "primary"
        }, {
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "撤销",
          "type": "danger"
        }, {
          "tag": "default",
          "type": "success",
          "icon": "",
          "title": "查看发票",
          "id": ""
        }],
        "showPagination": true,
        "showSelection": true,
        "showSummary": true,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {
          "address": {
            "data": [],
            "id": 7,
            "multiple": 0,
            "remote": true,
            "remoteFunc": "tableFunc_cascader_address"
          }
        },
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单号",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "order_id"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "支付状态",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "80",
          "prop": "paid"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单类型",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "order_type"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "取货方式",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "80",
          "prop": "take_type"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "套餐数量",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_num"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "number"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "ex_number.real_name"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "直属编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "direct"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "报单人编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "store_number"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "物流状态",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "status"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "套餐信息",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": "300",
          "prop": "package_info"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单金额",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_price"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "账户支付",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "money1_price"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "微信支付",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "wechat_price"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "扣复销",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "money4_price"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "运费",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "express_fee"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单PV",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_pv"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "支付日期",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "pay_time"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "下单期数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "expect_num"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "支付期数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "pay_expect_num"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货人",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "real_name"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货电话",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "user_phone"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "快递信息",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": "300",
          "prop": "delivery_info"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "省市区",
          "required": 1,
          "rules": [],
          "show": 0,
          "type": "cascader",
          "width": "100",
          "prop": "address",
          "data": {
            "data": [],
            "id": 7,
            "key": "address",
            "multiple": 0,
            "remote": true
          }
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货地址",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "300",
          "prop": "address_detail"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "省",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "address_province"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "市",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "address_city"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "邮编",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "address_post_code"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "remark"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "用户备注",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "user_remark"
        }],
        "tableName": "t_order",
        "tableRadioProps": {},
        "tableSelectProps": {
          "status": {
            "data": [{
              "key": -1,
              "value": "申请退款"
            }, {
              "key": -2,
              "value": "退货成功"
            }, {
              "key": 0,
              "value": "未出库"
            }, {
              "key": 1,
              "value": "部分出库"
            }, {
              "key": 2,
              "value": "全部出库"
            }, {
              "key": 3,
              "value": "部分发货"
            }, {
              "key": 4,
              "value": "全部发货"
            }, {
              "key": 5,
              "value": "完成"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationId": "",
            "remote": false
          },
          "order_type": {
            "data": [{
              "key": 1,
              "value": "注册单"
            }, {
              "key": 2,
              "value": "重购单"
            }, {
              "key": 3,
              "value": "升级单"
            }, {
              "key": 4,
              "value": "激活单"
            }],
            "id": 4,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "paid": {
            "data": [{
              "key": 0,
              "value": "未支付"
            }, {
              "key": 1,
              "value": "已支付"
            }],
            "id": 6,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "take_type": {
            "data": [{
              "key": 0,
              "value": "邮寄"
            }, {
              "key": 1,
              "value": "自提"
            }],
            "id": 8,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "订单",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_14: function modelConditionFunc_14(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "pay_time",
            value: []
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "pay_time",
            value: []
          }]];
          resolve(tag, data);
        },
        tableFunc_cascader_address: function tableFunc_cascader_address(tag, relationData, resolve) {
          //筛选项模型数组
          areaList({}).then(function _callee(res) {
            var i, data;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    for (i in res) {
                      _this.loopArea(res[i]);
                    }

                    data = res;
                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    loopArea: function loopArea(dic) {
      dic.key = dic.name;
      dic.value = dic.name;

      if (!dic.hasOwnProperty('children')) {
        return;
      }

      for (var i in dic.children) {
        this.loopArea(dic.children[i]);
      }
    },
    reqTableData: function reqTableData(params, resolve) {
      var _this2 = this;

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      orderList(params).then(function _callee2(res) {
        var i, orderDetail, orderDelivery, msg, omsg1, omsg2, j, item, store_name, price, _j;

        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                for (i in res.rows) {
                  orderDetail = res.rows[i]['t_order_packages'];
                  orderDelivery = res.rows[i]['t_order_expresses'];
                  msg = '';
                  omsg1 = '';
                  omsg2 = '';

                  for (j in orderDetail) {
                    item = orderDetail[j];
                    store_name = item.ex_package_product ? item.ex_package_product.store_name : '订单异常';
                    price = item.ex_package_product ? item.ex_package_product.price : '订单异常';
                    msg += '<div>';
                    msg += '<span style="color:red">[' + item.quantity + ']</span>';
                    msg += ' ' + store_name + ' ' + price + '￥';
                    msg += '</div>';
                    omsg1 += '[' + item.quantity + ']';
                    omsg1 += ' ' + store_name + ' ' + price;
                  }

                  res.rows[i]['package_info'] = msg;
                  msg = '';

                  for (_j in orderDelivery) {
                    msg += '<div>';
                    msg += '<span style="color:red">[' + (parseInt(_j) + 1) + ']</span>';
                    msg += ' ' + orderDelivery[_j].express_code + orderDelivery[_j].express_no + ' ' + orderDelivery[_j].add_time;
                    msg += '</div>';
                    omsg2 += '[' + (parseInt(_j) + 1) + ']';
                    omsg2 += ' ' + orderDelivery[_j].express_code + orderDelivery[_j].express_no + ' ' + orderDelivery[_j].add_time;
                  }

                  res.rows[i]['delivery_info'] = msg;
                  res.rows[i]['package_info_o'] = omsg1;
                  res.rows[i]['delivery_info_o'] = omsg2;
                  res.rows[i]['address'] = [];
                  res.rows[i]['address'].push(res.rows[i]['address_province']);
                  res.rows[i]['address'].push(res.rows[i]['address_city']);
                  res.rows[i]['address'].push(res.rows[i]['address_district']);
                }

                _this2.sum = res.sum;
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 3:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.order_id = row.order_id;
      }

      params.address_province = params.address[0];
      params.address_city = params.address[1];
      params.address_district = params.address[2];
      orderAdd(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      resolve();
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this3 = this;

      //点击列表按钮回调
      if (btnIndex === 1) {
        var orders = [row.order_id];
        this.confirm(this.$t('确定要撤销订单号为{id}的订单么？', {
          id: orders[0]
        }), function () {
          orderCancel({
            order_id: orders[0]
          }).then(function _callee4(res) {
            return regeneratorRuntime.async(function _callee4$(_context4) {
              while (1) {
                switch (_context4.prev = _context4.next) {
                  case 0:
                    _this3.$message({
                      message: '撤单成功',
                      type: 'success'
                    });

                    _this3.table.reloadData();

                  case 2:
                  case "end":
                    return _context4.stop();
                }
              }
            });
          });
        });
      } else if (btnIndex == 2) {
        window.open('https://back.youandmeintl.com/api/public/invoice/' + row.order_id + '.pdf');
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this4 = this;

      //点击列表头部按钮回调
      if (row.length <= 0) {
        this.$message({
          message: '请先选择一个订单',
          type: 'warning'
        });
        return;
      }

      var orders = row.map(function (item) {
        return item.order_id;
      });
      this.confirm('确定要生成"' + orders[0] + '"的发票么？', function () {
        _this4.$g.fun.startLoading('生成中...');

        orderInvoice({
          order_id: orders[0]
        }).then(function _callee5(res) {
          return regeneratorRuntime.async(function _callee5$(_context5) {
            while (1) {
              switch (_context5.prev = _context5.next) {
                case 0:
                  _this4.$g.fun.endLoading();

                  window.open(res);

                case 2:
                case "end":
                  return _context5.stop();
              }
            }
          });
        }).catch(function () {
          _this4.$g.fun.endLoading();
        });
      });
    },
    onSummary: function onSummary(param) {
      var _this5 = this;

      //列表尾部合计回调
      var columns = param.columns,
          data = param.data;
      console.log('para', param);
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }

        if (column.property === 'total_price') {
          sums[index] = _this5.sum.total_price;
        } else if (column.property === 'money1_price') {
          sums[index] = _this5.sum.money1_price;
        } else if (column.property === 'money4_price') {
          sums[index] = _this5.sum.money4_price;
        } else if (column.property === 'total_num') {
          sums[index] = _this5.sum.total_num;
        } else if (column.property === 'wechat_price') {
          sums[index] = _this5.sum.wechat_price;
        } else if (column.property === 'total_pv') {
          sums[index] = _this5.sum.total_pv;
        }
      });
      return sums;
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      var _this6 = this;

      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcelCustom(function (ret, isAll, columns, data) {
          var tmp = _this6.$tempModel.deepCopy(data);

          for (var i = 0; i < tmp.length; i++) {
            tmp[i]['package_info'] = tmp[i]['package_info_o'];
            tmp[i]['delivery_info'] = tmp[i]['delivery_info_o'];
          }

          _this6.$export.excel({
            columns: columns,
            data: tmp,
            title: '订单列表'
          }).then(function () {
            _this6.$g.fun.endLoading();
          });
        }); // this.table.exportExcel()
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
        this.confirm('确定更新表格中' + results.length + '条物流信息么？', function () {
          var params = {};
          var data = [];

          try {
            for (var i in results) {
              var item = results[i];
              data.push({
                order_id: item['订单编号'],
                express_code: item['物流公司'],
                express_no: item['快递编号']
              });
            }

            params.data = data;
            expressImport(params).then(function _callee6(res) {
              return regeneratorRuntime.async(function _callee6$(_context6) {
                while (1) {
                  switch (_context6.prev = _context6.next) {
                    case 0:
                      _this6.$message({
                        message: '物流信息更新成功',
                        type: 'success'
                      });

                      _this6.table.reloadData();

                    case 2:
                    case "end":
                      return _context6.stop();
                  }
                }
              });
            });
          } catch (err) {
            _this6.$message({
              message: '表格格式错误',
              type: 'error'
            });
          }
        });
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    confirm: function confirm(msg, handle) {
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (handle) {
          handle();
        }
      }).catch(function () {});
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      if (btnIndex === 0) {
        if (row.status === 0) {
          return true;
        }

        return false;
      } else if (btnIndex === 1) {
        var now = new Date();
        var nowDate = new Date(now.toLocaleDateString() + ' 00:00:00');
        var orderDate = new Date(row.add_time);

        if (orderDate < nowDate) {
          return false;
        }
      } else if (btnIndex === 2) {
        if (row.invoice_no == null) {
          return false;
        }
      }

      return true;
    }
  }
};