import { render, staticRenderFns } from "./addMove.vue?vue&type=template&id=b00d0924&scoped=true&"
import script from "./addMove.vue?vue&type=script&lang=js&"
export * from "./addMove.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b00d0924",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/suifeng/Desktop/projects/2022/sdML/sdMLAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b00d0924')) {
      api.createRecord('b00d0924', component.options)
    } else {
      api.reload('b00d0924', component.options)
    }
    module.hot.accept("./addMove.vue?vue&type=template&id=b00d0924&scoped=true&", function () {
      api.rerender('b00d0924', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/member/memberMove/addMove.vue"
export default component.exports