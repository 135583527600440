import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberBatchRecharge, memberMoveAdd } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        number: '',
        to_number: ''
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(finish) {
      this.dialogVisible = true;
      this.finish = finish;
      this.form = {
        number: '',
        to_number: ''
      };
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      if (this.form.number === '' || this.form.to_number === '') {
        this.$message({
          message: this.$t('请输入会员编号和目标会员'),
          type: 'error'
        });
        return;
      }

      this.$confirm(this.$t('确定要执行批量操作么，移动过程会停止前端访问，移动结束后自动开启'), this.$t('提示'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(function () {
        _this.$g.fun.startLoading();

        memberMoveAdd({
          from_number: _this.form.number,
          to_number: _this.form.to_number
        }).then(function _callee(res) {
          return regeneratorRuntime.async(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  _this.$message({
                    message: _this.$t('点位移动成功'),
                    type: 'success'
                  });

                  _this.dialogVisible = false;

                  _this.finish();

                  _this.$g.fun.endLoading();

                case 4:
                case "end":
                  return _context.stop();
              }
            }
          });
        }).catch(function () {
          _this.$g.fun.endLoading();
        });
      }).catch(function () {});
    }
  }
};