import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberMove, memberRepairMoney } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      tagList: [{
        key: 0,
        value: this.$t('补款')
      }, {
        key: 1,
        value: this.$t('扣款')
      }],
      accountList: [{
        key: 'money1',
        value: this.$t('消费钱包')
      }, {
        key: 'money2',
        value: this.$t('奖金账户')
      } // {key:'money3',value:this.$t('充值中心')},
      // {key:'money4',value:this.$t('复销账户')},
      ],
      form: {
        number: '',
        type: 'money1',
        tag: 0,
        happen_money: '',
        remark: ''
      },
      rules: {
        happen_money: [{
          require: true,
          validator: this.$tempModel.rules.money.validator,
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(number, finish) {
      this.dialogVisible = true;
      this.form.number = number;
      this.finish = finish;
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          memberRepairMoney(_this.form).then(function _callee(res) {
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    _this.$message({
                      message: '操作成功',
                      type: 'success'
                    });

                    _this.dialogVisible = false;

                    if (_this.finish) {
                      _this.finish();
                    }

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        }
      });
    }
  }
};