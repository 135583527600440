import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import tempForm from '@/components/rw/tempForm/index';
import tempChart from '@/components/rw/tempChart/index';
import { areaAdd, areaList, areaListByPid, areaDel } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      region: [],
      options: [],
      tableModel_1001: {
        'type': 'tableTemplate',
        'id': 'id_1001',
        'del': false,
        'tableModel': [{
          'label': 'ID',
          'show': 1,
          'add': 0,
          'detail': 1,
          'width': null,
          'type': 'text',
          'desc': '',
          'groupId': '',
          'rules': [],
          'defaultValue': 80,
          'prop': 'id'
        }, {
          'label': '名称',
          'show': 1,
          'add': 0,
          'detail': 1,
          'width': null,
          'type': 'text',
          'desc': '',
          'groupId': '',
          'rules': [],
          'defaultValue': 150,
          'prop': 'name'
        }, {
          'label': '邮费',
          'show': 1,
          'add': 1,
          'detail': 1,
          'width': null,
          'type': 'text',
          'desc': '',
          'groupId': '',
          'rules': [],
          'defaultValue': 150,
          'prop': 'express_fee'
        }],
        'tableSwitchProps': {},
        'tableSelectProps': {},
        'tableCascaderProps': {},
        'tableFileProps': {},
        'rowButton': [{
          'title': '设置邮费',
          'type': 'primary',
          'tag': 'edit',
          'id': ''
        }],
        'stripe': true,
        'border': true,
        'showPagination': true,
        'showSelection': false,
        'showSummary': false,
        'title': '地区邮费',
        'tableName': '',
        'isGroup': false,
        'isTree': true,
        'headerButton': [],
        'tableAutocompleteProps': {},
        'tableRadioProps': {},
        'defaultExpandAll': false,
        'rowKey': 'id',
        'treeProps': {
          'children': 'children',
          'hasChildren': 'hasChildren'
        }
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
  },
  methods: {
    load: function load(tree, treeNode, resolve) {
      areaListByPid({
        pid: tree.id
      }).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve(res);

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqTableData: function reqTableData(params, resolve) {
      areaListByPid({
        pid: -1
      }).then(function _callee2(res) {
        var i;
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                for (i in res) {
                  res[i].hasChildren = true;
                }

                resolve({
                  total: 0,
                  data: res
                });

              case 2:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      areaAdd(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {// let ids = params.map(item => item.id)
      // areaDel({ id: ids }).then(async res => {
      //   resolve()
      // })
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};