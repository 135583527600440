import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { getProductV2 } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "type": "searchTemplate",
        "id": "id_1000",
        "del": false,
        "searchData": [{
          "title": "统计日期",
          "select": 0,
          "type": 1,
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "今天",
            "field": []
          }, {
            "type": "button",
            "title": "昨天",
            "field": []
          }, {
            "type": "button",
            "title": "最近7天",
            "field": []
          }, {
            "type": "button",
            "title": "本月",
            "field": []
          }, {
            "type": "button",
            "title": "本年",
            "field": []
          }, {
            "type": "custom-datetime-range",
            "title": "自定义",
            "field": []
          }],
          "remoteFunc": ["modelConditionFunc_0"]
        }],
        "operateData": [{
          "title": "搜索",
          "type": "primary",
          "tag": "search",
          "icon": "el-icon-search"
        }, {
          "title": "导出",
          "type": "primary",
          "tag": "export",
          "icon": "el-icon-position"
        }],
        "isCollapse": false
      },
      tableModel_1001: {
        "type": "tableTemplate",
        "id": "id_1001",
        "del": false,
        "tableModel": [],
        "tableSwitchProps": {},
        "tableSelectProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [],
        "stripe": true,
        "border": true,
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "title": "统计",
        "tableName": "",
        "isGroup": false,
        "isTree": false,
        "headerButton": [],
        "tableAutocompleteProps": {},
        "tableRadioProps": {}
      },
      remoteFunc: {
        modelConditionFunc_0: function modelConditionFunc_0(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    var _this2 = this;

    this.search = this.$refs.ref_1000;
    var params = {};
    params.daterange = [this.getDay(-1), this.getDay(0)];
    getProductV2(params).then(function _callee(res) {
      var date, item, tableModel, j, itemData;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              for (date in res.days_dic) {
                item = res.days_dic[date];
                tableModel = [{
                  "add": 0,
                  "desc": "",
                  "detail": 1,
                  "excel": 1,
                  "groupId": "",
                  "label": "日期",
                  "rules": [],
                  "show": 1,
                  "type": "text",
                  "width": null,
                  "prop": "date"
                }];

                for (j in item.detail) {
                  itemData = {
                    "add": 0,
                    "desc": "",
                    "detail": 1,
                    "excel": 1,
                    "groupId": "",
                    "label": "",
                    "rules": [],
                    "show": 1,
                    "type": "text",
                    "width": null,
                    "prop": ""
                  };
                  itemData.label = item.detail[j].name;
                  itemData.prop = item.detail[j].id + "";
                  tableModel.push(itemData);
                }

                _this2.tableModel_1001.tableModel = tableModel;
                console.log("tableModel", tableModel);
              }

              _this2.$nextTick(function () {
                _this2.table = _this2.$refs.ref_1001;

                _this2.table.reloadData();
              });

            case 2:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    getDay: function getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码

      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + '-' + tMonth + '-' + tDate;
    },
    doHandleMonth: function doHandleMonth(month) {
      var m = month;

      if (month.toString().length == 1) {
        m = '0' + month;
      }

      return m;
    },
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      if (params.where.hasOwnProperty('add_time')) {
        params.daterange = params.where.add_time;
      } else {
        if (this.tableModel_1001.tableModel.length > 0) {
          console.log('22222');
          params.daterange = [this.getDay(-7), this.getDay(0)];
        } else {
          console.log('11111');
          resolve({
            total: 0,
            data: []
          });
          return;
        }
      }

      getProductV2(params).then(function _callee2(res) {
        var data, date, item, itemKeyData, j;
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                data = [];

                for (date in res.days_dic) {
                  item = res.days_dic[date];
                  itemKeyData = {
                    date: date
                  };

                  for (j in item.detail) {
                    itemKeyData[item.detail[j].id + ""] = item.detail[j].quantity;
                  }

                  data.push(itemKeyData);
                }

                console.log('aa', data);
                resolve({
                  total: 0,
                  data: data
                });

              case 4:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};