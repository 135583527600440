import { render, staticRenderFns } from "./index.vue?vue&type=template&id=00d25bfc&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d25bfc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/suifeng/Desktop/projects/2022/sdML/sdMLAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00d25bfc')) {
      api.createRecord('00d25bfc', component.options)
    } else {
      api.reload('00d25bfc', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=00d25bfc&scoped=true&", function () {
      api.rerender('00d25bfc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/member/memberBonusLog/index.vue"
export default component.exports