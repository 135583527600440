//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    events: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    //各种事件
    data: {
      type: Object,
      required: true
    },
    settings: {
      type: Object
    },
    grid: {
      type: Object
    },
    colors: {
      type: Array,
      default: function _default() {
        return null;
      }
    },
    remoteFunc: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    remoteFuncName: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      yAxis: [{
        axisLabel: {
          show: true,
          textStyle: {
            color: 'white',
            //这里是改变字体颜色
            fontSize: 18 //字体大小

          }
        },
        splitLine: {
          show: false
        }
      }],
      xAxis: [{
        data: ["裁床部", "车缝部", "电绣部", "喷印部", "手工部", "塑胶包装部", "委外加工", "注塑部"],
        name: "日期",
        axisLabel: {
          show: true,
          textStyle: {
            color: "white",
            fontSize: 18
          }
        },
        splitLine: {
          show: false
        }
      }]
    };
  },
  mounted: function mounted() {
    this.reloadData();
  },
  methods: {
    reloadData: function reloadData() {
      var _this = this;

      if (this.remoteFunc.hasOwnProperty(this.remoteFuncName)) {
        this.remoteFunc[this.remoteFuncName](function (columns, data) {
          _this.data.columns = columns;
          _this.data.rows = data;
        });
      }
    }
  }
};