var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === _vm.$tempModel.searchType.button
        ? _c(
            "el-button",
            {
              attrs: { size: "mini", type: _vm.buttonType, round: "" },
              on: { click: _vm.onClick }
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          )
        : _vm._e(),
      _vm.type === _vm.$tempModel.searchType.buttonNumber
        ? _c(
            "el-button",
            {
              attrs: { size: "mini", type: _vm.buttonType, round: "" },
              on: { click: _vm.onClick }
            },
            [
              _vm._v(" " + _vm._s(_vm.title) + " "),
              _c("span", { staticClass: "button-number" }, [
                _vm._v(_vm._s(_vm.total))
              ])
            ]
          )
        : _vm._e(),
      _vm.type === _vm.$tempModel.searchType.buttonNumberMajor
        ? _c(
            "el-button",
            {
              attrs: { size: "mini", type: _vm.buttonType, round: "" },
              on: { click: _vm.onClick }
            },
            [
              _vm._v(" " + _vm._s(_vm.title) + " "),
              _c("span", { staticClass: "button-number-major" }, [
                _vm._v(_vm._s(_vm.total))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }